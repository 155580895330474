//antd override css
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomRight {
  min-width: 150px !important;
  left: 1154px !important;
}
textarea.ant-input {
  height: 70px !important;
}
.ant-drawer-header {
  display: none !important;
}
.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  width: 350px !important;
}
.profile-drawer {
  .profile-form-button {
    width: 140px;
    height: 40px;
    border-radius: 5px;
    background-color: #2e5bff;
    border-color: #2e5bff;
    color: #fff;
    &:hover,
    &:focus {
      color: #fff;
      background-color: #2e59d9;
      border-color: #2653d4;
    }
  }
}
.submit-button {
  width: 200px;
  height: 40px;
  border-radius: 5px;
  background-color: #2e5bff;
  border-color: #2e5bff;
  color: #fff;
  &:hover,
  &:focus {
    color: #fff;
    background-color: #2e59d9;
    border-color: #2653d4;
  }
}
.transaction-modal {
  .ant-modal {
    width: 700px !important;
  }
  .ant-modal-footer {
    display: none;
  }
  @media (max-width: 575.98px) {
    .filter-detail {
      display: block !important;
    }
    .credit-form1 {
      border-bottom: 2px solid black;
      margin-bottom: 15px;
    }
  }
}
.transaction-input {
  .ant-input,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: rgba(224, 231, 255, 0.2) !important;
    border: 1px solid #e0e7ff !important;
    border-radius: 5px !important;
  }
}
.credit-modal {
  @media (max-width: 575.98px) {
    .ant-modal-content {
      margin-left: 20px !important;
      margin-right: 20px !important;
    }
  }
}
