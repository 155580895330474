$colors: (
  white: #fff,
  light: #F1F3F4,
  darkgray: #373F43,
  gray:#8798AD,
  gray_checkmark:#8C9098,
  lightgray: #BFC5D2,
);
$maxLoop: 100;
$step: 5;
