.setting-tb-header {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 1.21333px;
  text-transform: uppercase;

  color: #8798ad !important;
}
