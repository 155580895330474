body,
div,
table,
thead,
tbody,
tfoot,
tr,
th,
td,
p {
  font-family: "Arial";
  font-size: small;
}

hr {
  border: none;
  /* border-top: 1px solid #000; */
  margin: 10px 0;
}

h1 {
  font-size: 24px;
  margin: 10px 0;
}

a {
  text-decoration: none;
  color: #000;
}

a:hover {
  color: red;
}

table {
  border-collapse: collapse;
  /* border: 1px solid #000; */
  width: 100%;
}

td,
th {
  padding: 5px;
  /* border: 1px solid #000; */
}

.logo {
  width: 130px;
  height: 130px;
  margin-left: 40px;
  margin-top: 5px;
}
.border-top {
  border-top: 1px solid #000;
}

.border-bottom {
  border-bottom: 1px solid #000;
}

.border-left {
  border-left: 1px solid #000;
}

.border-right {
  border-right: 1px solid #000;
}

.border-all {
  border: 1px solid #000;
}
