@font-face {
  font-family: "rubik-light";
  src: url("./../font/Rubik-Light.ttf"); /* IE9 Compat Modes */
  src: url("./../font/Rubik-Light.ttf") format("truetype"); /* Safari, Android, iOS */
  font-display: swap;
}
@font-face {
  font-family: "rubik-regular";
  src: url("./../font/Rubik-Regular.ttf"); /* IE9 Compat Modes */
  src: url("./../font/Rubik-Regular.ttf") format("truetype"); /* Safari, Android, iOS */
  font-display: swap;
}
@font-face {
  font-family: "rubik-bold";
  src: url("./../font/Rubik-Bold.ttf"); /* IE9 Compat Modes */
  src: url("./../font/Rubik-Bold.ttf") format("truetype"); /* Safari, Android, iOS */
  font-display: swap;
}
@font-face {
  font-family: "rubik-semi-bold";
  src: url("./../font/Rubik-SemiBold.ttf"); /* IE9 Compat Modes */
  src: url("./../font/Rubik-SemiBold.ttf") format("truetype"); /* Safari, Android, iOS */
  font-display: swap;
}
@font-face {
  font-family: "rubik-medium";
  src: url("./../font/Rubik-Medium.ttf"); /* IE9 Compat Modes */
  src: url("./../font/Rubik-Medium.ttf") format("truetype"); /* Safari, Android, iOS */
  font-display: swap;
}
