.heading {
  /* position: absolute; */
  width: 301px;
  height: 32px;
  left: calc(50% - 301px / 2 - 9.5px);
  top: 0px;

  font-family: "Rubik";
  font-style: normal;
  font-weight: 300 !important;
  font-size: 34px !important;
  line-height: 32px !important;
  /* identical to box height, or 94% */

  color: #2e384d !important;
}

.sub-heading {
  /* position: absolute; */
  width: 320px;
  height: 22px;
  left: calc(50% - 320px / 2);
  top: 42px;

  font-family: "Rubik";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 22px !important;
  /* identical to box height, or 147% */

  color: #8798ad;
}

.form {
  margin: 0 !important;
  margin-bottom: 10px !important;
}

.title-head {
  height: 14px;
  left: 0px;
  top: 0px;
  margin-bottom: 8px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  letter-spacing: 1.125px !important;
  text-transform: uppercase !important;
  display: flex;
  justify-content: space-between;
  color: #b0bac9;
}

.title-head-dahsboard {
  /* height: 14px; */
  left: 0px;
  top: 0px;
  margin-bottom: 8px;
  padding-inline: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500 !important;
  /* font-size: 12px !important; */
  /* line-height: 14px !important; */
  letter-spacing: 1.125px !important;
  text-transform: uppercase !important;
  display: flex;
  justify-content: space-between;
  color: #b0bac9;
}

.login-logo {
  margin-bottom: 30px;
}

.register-slide {
  margin-top: 25px;
  margin-bottom: 25px;
}

.form-2 {
  display: flex;
  justify-content: center;
  width: 350px;
  /* row-gap: 4px; */
}

.forget-screen {
  width: 100%;
  height: 100vh;
  background-color: #e6e4e2;
}

.forget-screen .forget-modal {
  align-content: center;
  align-items: center;
  justify-content: center;
}

.forget-screen .forget-modal .forget-form {
  width: 653px;
  height: 391px;
  padding: 60px 120px;
  background-color: #ffffff;
}

.sub-heading2 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  /* or 147% */
  width: 400px;
  text-align: center;

  color: #8798ad;
}
.heading2 {
  height: 32px;
  left: 40.49%;
  right: 40.42%;
  top: calc(50% - 32px / 2 - 120px);
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 34px;
  line-height: 32px;
  /* identical to box height, or 94% */

  text-align: center;

  color: #2e384d;
}
.image-forget {
  display: flex;
  justify-content: center;
  align-items: center;
}
.reuired-field::after {
  content: "*";
  color: red;
  margin-right: 10px;
}
