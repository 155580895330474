@import "./mixins.scss";
@import "./variable.scss";
@import "./helper.scss";
@import "./font.scss";
@import "./antd-override.scss";

body {
  margin: 0;
  padding: 0 !important;
  line-height: 1.3 !important;
  background-color: #F4F6FC !important;
  font-family: 'Rubik', sans-serif;
  overflow-x: hidden !important;
}

.app-container {
  max-width: 1160px;
  margin: 0 auto;
}

::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b6b6b6;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

html {
  scroll-behavior: smooth;
}

// .table-container::-webkit-scrollbar {
//   height: 10px;
// }
// .table-container::-webkit-scrollbar-thumb {
//   background: #393964;
// }
// .table-container::-webkit-scrollbar-track {
//   background: #0D0D26;
// }
// .mobile_stack_card::-webkit-scrollbar {
//   height: 10px;
// }
// .mobile_stack_card::-webkit-scrollbar-thumb {
//   background: #393964;
// }
// .mobile_stack_card::-webkit-scrollbar-track {
//   background: #0D0D26;
// }