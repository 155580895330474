.bilty html {
  font-family: Calibri, Arial, Helvetica, sans-serif;
  font-size: 11pt;
  background-color: white;
}
.bilty a.comment-indicator:hover + div.comment {
  background: #ffd;
  position: absolute;
  display: block;
  border: 1px solid black;
  padding: 0.5em;
}
.bilty a.comment-indicator {
  background: red;
  display: inline-block;
  border: 1px solid black;
  width: 0.5em;
  height: 0.5em;
}
.bilty div.comment {
  display: none;
}
.bilty table {
  border-collapse: collapse;
  page-break-after: always;
}
.bilty .gridlines td {
  border: 1px dotted black;
}
.bilty .gridlines th {
  border: 1px dotted black;
}
.bilty .b {
  text-align: center;
}
.bilty .e {
  text-align: center;
}
.bilty .f {
  text-align: right;
}
.bilty .inlineStr {
  text-align: left;
}
.bilty .n {
  text-align: right;
}
.bilty .s {
  text-align: left;
}
.bilty td.style0 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Arial";
  font-size: 10pt;
  background-color: white;
}
.bilty th.style0 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Arial";
  font-size: 10pt;
  background-color: white;
}
.bilty td.style1 {
  vertical-align: middle;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty th.style1 {
  vertical-align: middle;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty td.style2 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 10pt;
  background-color: white;
}
.bilty th.style2 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 10pt;
  background-color: white;
}
.bilty td.style3 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty th.style3 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty td.style4 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Rubik";
  font-size: 16pt;
  background-color: white;
}
.bilty th.style4 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Rubik";
  font-size: 16pt;
  background-color: white;
}
.bilty td.style5 {
  vertical-align: middle;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty th.style5 {
  vertical-align: middle;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty td.style6 {
  vertical-align: middle;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty th.style6 {
  vertical-align: middle;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty td.style7 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty th.style7 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty td.style8 {
  vertical-align: middle;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty th.style8 {
  vertical-align: middle;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty td.style9 {
  vertical-align: middle;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty th.style9 {
  vertical-align: middle;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty td.style10 {
  vertical-align: bottom;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Calibri";
  font-size: 10pt;
  background-color: white;
}
.bilty th.style10 {
  vertical-align: bottom;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Calibri";
  font-size: 10pt;
  background-color: white;
}
.bilty td.style11 {
  vertical-align: bottom;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Calibri";
  font-size: 10pt;
  background-color: white;
}
.bilty th.style11 {
  vertical-align: bottom;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Calibri";
  font-size: 10pt;
  background-color: white;
}
.bilty td.style12 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty th.style12 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty td.style13 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty th.style13 {
  vertical-align: middle;
  text-align: right;
  padding-right: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty td.style14 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty th.style14 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty td.style15 {
  vertical-align: middle;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty th.style15 {
  vertical-align: middle;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty td.style16 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #434343 !important;
  border-left: none #000000;
  border-right: none #000000;
  color: #434343;
  font-family: "Rubik";
  font-size: 10pt;
  background-color: white;
}
.bilty th.style16 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: none #000000;
  border-top: 2px solid #434343 !important;
  border-left: none #000000;
  border-right: none #000000;
  color: #434343;
  font-family: "Rubik";
  font-size: 10pt;
  background-color: white;
}
.bilty td.style17 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: 2px solid #434343 !important;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Calibri";
  font-size: 10pt;
  background-color: white;
}
.bilty th.style17 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: 2px solid #434343 !important;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Calibri";
  font-size: 10pt;
  background-color: white;
}
.bilty td.style18 {
  vertical-align: top;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #434343;
  font-family: "Rubik";
  font-size: 10pt;
  background-color: #ffffff;
}
.bilty th.style18 {
  vertical-align: top;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #434343;
  font-family: "Rubik";
  font-size: 10pt;
  background-color: #ffffff;
}
.bilty td.style19 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty th.style19 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty td.style20 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Rubik";
  font-size: 16pt;
  background-color: #ffffff;
}
.bilty th.style20 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Rubik";
  font-size: 16pt;
  background-color: #ffffff;
}
.bilty td.style21 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty th.style21 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty td.style22 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty th.style22 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty td.style23 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #1f1f1f;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: #ffffff;
}
.bilty th.style23 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #1f1f1f;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: #ffffff;
}
.bilty td.style24 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #1f1f1f;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: #ffffff;
}
.bilty th.style24 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #1f1f1f;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: #ffffff;
}
.bilty td.style25 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #434343 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty th.style25 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 2px solid #434343 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty td.style26 {
  vertical-align: bottom;
  border-bottom: 2px solid #434343 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Calibri";
  font-size: 10pt;
  background-color: white;
}
.bilty th.style26 {
  vertical-align: bottom;
  border-bottom: 2px solid #434343 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Calibri";
  font-size: 10pt;
  background-color: white;
}
.bilty td.style27 {
  vertical-align: bottom;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty th.style27 {
  vertical-align: bottom;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty td.style28 {
  vertical-align: bottom;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Calibri";
  font-size: 10pt;
  background-color: white;
}
.bilty th.style28 {
  vertical-align: bottom;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Calibri";
  font-size: 10pt;
  background-color: white;
}
.bilty td.style29 {
  vertical-align: bottom;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Calibri";
  font-size: 10pt;
  background-color: white;
}
.bilty th.style29 {
  vertical-align: bottom;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Calibri";
  font-size: 10pt;
  background-color: white;
}
.bilty td.style30 {
  vertical-align: bottom;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty th.style30 {
  vertical-align: bottom;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty td.style31 {
  vertical-align: bottom;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty th.style31 {
  vertical-align: bottom;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty td.style32 {
  vertical-align: bottom;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty th.style32 {
  vertical-align: bottom;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty td.style33 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty th.style33 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty td.style34 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty th.style34 {
  vertical-align: bottom;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty td.style35 {
  vertical-align: middle;
  border-bottom: none #000000;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty th.style35 {
  vertical-align: middle;
  border-bottom: none #000000;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty td.style36 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Calibri";
  font-size: 10pt;
  background-color: white;
}
.bilty th.style36 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Calibri";
  font-size: 10pt;
  background-color: white;
}
.bilty td.style37 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty th.style37 {
  vertical-align: middle;
  text-align: left;
  padding-left: 0px;
  border-bottom: none #000000;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty td.style38 {
  vertical-align: bottom;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Calibri";
  font-size: 10pt;
  background-color: white;
}
.bilty th.style38 {
  vertical-align: bottom;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Calibri";
  font-size: 10pt;
  background-color: white;
}
.bilty td.style39 {
  vertical-align: bottom;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Calibri";
  font-size: 10pt;
  background-color: white;
}
.bilty th.style39 {
  vertical-align: bottom;
  border-bottom: 1px solid #000000 !important;
  border-top: none #000000;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Calibri";
  font-size: 10pt;
  background-color: white;
}
.bilty td.style40 {
  vertical-align: bottom;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty th.style40 {
  vertical-align: bottom;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty td.style41 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty th.style41 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty td.style42 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty th.style42 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty td.style43 {
  vertical-align: bottom;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty th.style43 {
  vertical-align: bottom;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty td.style44 {
  vertical-align: bottom;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty th.style44 {
  vertical-align: bottom;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty td.style45 {
  vertical-align: bottom;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty th.style45 {
  vertical-align: bottom;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty td.style46 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty th.style46 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty td.style47 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Calibri";
  font-size: 10pt;
  background-color: white;
}
.bilty th.style47 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: 1px solid #000000 !important;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Calibri";
  font-size: 10pt;
  background-color: white;
}
.bilty td.style48 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Calibri";
  font-size: 10pt;
  background-color: white;
}
.bilty th.style48 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Calibri";
  font-size: 10pt;
  background-color: white;
}
.bilty td.style49 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Calibri";
  font-size: 10pt;
  background-color: white;
}
.bilty th.style49 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Calibri";
  font-size: 10pt;
  background-color: white;
}
.bilty td.style50 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty th.style50 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty td.style51 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty th.style51 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty td.style52 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 2px solid #434343 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty th.style52 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 2px solid #434343 !important;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Rubik";
  font-size: 18pt;
  background-color: white;
}
.bilty td.style53 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 10pt;
  background-color: white;
}
.bilty th.style53 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Rubik";
  font-size: 10pt;
  background-color: white;
}
.bilty table.sheet0 col.col0 {
  width: 42pt;
}
.bilty table.sheet0 col.col1 {
  width: 115.2222209pt;
}
.bilty table.sheet0 col.col2 {
  width: 63.03333261pt;
}
.bilty table.sheet0 col.col3 {
  width: 82.01111017pt;
}
.bilty table.sheet0 col.col4 {
  width: 72.52222139pt;
}
.bilty table.sheet0 col.col5 {
  width: 72.52222139pt;
}
.bilty table.sheet0 tr {
  height: 15.75pt;
}
.bilty table.sheet0 tr.row7 {
  height: 23.25pt;
}
.bilty table.sheet0 tr.row8 {
  height: 22.5pt;
}
.bilty table.sheet0 tr.row9 {
  height: 21pt;
}
.bilty table.sheet0 tr.row10 {
  height: 24pt;
}
.bilty table.sheet0 tr.row11 {
  height: 23.25pt;
}
.bilty table.sheet0 tr.row13 {
  height: 23.25pt;
}
.bilty table.sheet0 tr.row14 {
  height: 44.25pt;
}

.ritz .waffle a {
  color: inherit;
}
.ritz .waffle .s1 {
  background-color: #ffffff;
  text-align: left;
  color: #000000;
  font-family: "docs-Rubik", Arial;
  font-size: 12pt;
  vertical-align: bottom;
  white-space: nowrap;
  direction: ltr;
  padding: 0;
}
.ritz .waffle .s10 {
  text-align: center;
  color: #000000;
  font-family: "docs-Rubik", Arial;
  font-size: 12pt;
  vertical-align: bottom;
  white-space: nowrap;
  direction: ltr;
  padding: 0;
}
.ritz .waffle .s2 {
  background-color: #ffffff;
  text-align: left;
  font-weight: bold;
  color: #000000;
  font-family: "docs-Rubik", Arial;
  font-size: 16pt;
  vertical-align: bottom;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
.ritz .waffle .s8 {
  border-bottom: 2px SOLID #000000;
  border-left: 2px SOLID #000000;
  background-color: #ffffff;
  text-align: left;
  color: #000000;
  font-family: "docs-Rubik", Arial;
  font-size: 12pt;
  vertical-align: middle;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
.ritz .waffle .s7 {
  border-bottom: 2px SOLID #000000;
  border-left: 2px SOLID #000000;
  background-color: #ffffff;
  text-align: left;
  color: #000000;
  font-family: "docs-Rubik", Arial;
  font-size: 12pt;
  vertical-align: middle;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
.ritz .waffle .s12 {
  background-color: #ffffff;
}
.ritz .waffle .s6 {
  border-bottom: 2px SOLID #000000;
  background-color: #ffffff;
  text-align: left;
  color: #000000;
  font-family: "docs-Rubik", Arial;
  font-size: 12pt;
  vertical-align: middle;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
.ritz .waffle .s3 {
  background-color: #ffffff;
  text-align: left;
  color: #000000;
  font-family: "docs-Rubik", Arial;
  font-size: 10pt;
  vertical-align: bottom;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
.ritz .waffle .s5 {
  background-color: #ffffff;
  text-align: left;
  color: #000000;
  font-family: "docs-Rubik", Arial;
  font-size: 12pt;
  vertical-align: middle;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
.ritz .waffle .bl {
  border-left: 2px SOLID #000000;
}
.ritz .waffle .br {
  border-right: 2px SOLID #000000;
}
.ritz .waffle .bt {
  border-top: 2px SOLID #000000;
}
.ritz .waffle .bb {
  border-bottom: 2px SOLID #000000;
}
.ritz .waffle .s0 {
  background-color: #ffffff;
  text-align: left;
  color: #000000;
  font-family: "docs-Rubik", Arial;
  font-size: 12pt;
  vertical-align: middle;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
.ritz .waffle .s4 {
  background-color: #ffffff;
  text-align: left;
  font-weight: bold;
  color: #000000;
  font-family: "docs-Rubik", Arial;
  font-size: 12pt;
  vertical-align: middle;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}

.ritz .waffleGate a {
  color: inherit;
}
.ritz .waffleGate .bl {
  border-left: 2px SOLID #000000;
}
.ritz .waffleGate .br {
  border-right: 2px SOLID #000000;
}
.ritz .waffleGate .bt {
  border-top: 2px SOLID #000000;
}
.ritz .waffleGate .bb {
  border-bottom: 2px SOLID #000000;
}
.ritz .waffleGate .s6 {
  border-bottom: 2px SOLID #434343;
  background-color: #ffffff;
  text-align: center;
  font-weight: bold;
  color: #000000;
  font-family: "docs-Rubik", Arial;
  font-size: 12pt;
  vertical-align: middle;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
.ritz .waffleGate .s2 {
  background-color: #ffffff;
  text-align: left;
  font-weight: bold;
  color: #000000;
  font-family: "docs-Rubik", Arial;
  font-size: 16pt;
  vertical-align: bottom;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
.ritz .waffleGate .s24 {
  background-color: #ffffff;
  text-align: center;
  color: #000000;
  font-family: "docs-Rubik", Arial;
  font-size: 10pt;
  vertical-align: bottom;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
.ritz .waffleGate .s4 {
  background-color: #ffffff;
  text-align: left;
  color: #1f1f1f;
  font-family: "docs-Rubik", Arial;
  font-size: 12pt;
  vertical-align: bottom;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
.ritz .waffleGate .s12 {
  border-bottom: 2px SOLID #000000;
  border-right: 2px SOLID #000000;
  background-color: #ffffff;
  text-align: left;
  color: #000000;
  font-family: "docs-Rubik", Arial;
  font-size: 12pt;
  vertical-align: middle;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
.ritz .waffleGate .s8 {
  border-bottom: 2px SOLID #000000;
  border-right: 2px SOLID #000000;
  background-color: #ffffff;
  text-align: left;
  font-weight: bold;
  color: #000000;
  font-family: "docs-Rubik", Arial;
  font-size: 12pt;
  vertical-align: bottom;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
.ritz .waffleGate .s17 {
  border-right: 2px SOLID #000000;
  background-color: #ffffff;
  text-align: center;
  color: #000000;
  font-family: "docs-Rubik", Arial;
  font-size: 12pt;
  vertical-align: bottom;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
.ritz .waffleGate .s16 {
  border-bottom: 2px SOLID #000000;
  border-right: 2px SOLID #000000;
  background-color: #ffffff;
  text-align: center;
  font-weight: bold;
  color: #000000;
  font-family: "docs-Rubik", Arial;
  font-size: 12pt;
  vertical-align: middle;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
.ritz .waffleGate .s15 {
  border-right: 2px SOLID #000000;
  background-color: #ffffff;
  text-align: center;
  font-weight: bold;
  color: #000000;
  font-family: "docs-Rubik", Arial;
  font-size: 12pt;
  vertical-align: middle;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
.ritz .waffleGate .s21 {
  border-bottom: 2px SOLID #434343;
  background-color: #ffffff;
  text-align: left;
  color: #000000;
  font-family: "docs-Rubik", Arial;
  font-size: 12pt;
  vertical-align: bottom;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
.ritz .waffleGate .s18 {
  border-bottom: 2px SOLID #000000;
  border-right: 2px SOLID #000000;
  background-color: #ffffff;
  text-align: center;
  color: #000000;
  font-family: "docs-Rubik", Arial;
  font-size: 12pt;
  vertical-align: bottom;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
.ritz .waffleGate .s9 {
  border-right: 2px SOLID #000000;
  background-color: #ffffff;
  text-align: left;
  color: #000000;
  font-family: "docs-Rubik", Arial;
  font-size: 12pt;
  vertical-align: bottom;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
.ritz .waffleGate .s3 {
  background-color: #ffffff;
  text-align: left;
  font-weight: bold;
  color: #000000;
  font-family: "docs-Rubik", Arial;
  font-size: 12pt;
  vertical-align: bottom;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
.ritz .waffleGate .s1 {
  background-color: #ffffff;
  text-align: left;
  color: #000000;
  font-family: "docs-Rubik", Arial;
  font-size: 12pt;
  vertical-align: bottom;
  white-space: nowrap;
  direction: ltr;
  padding: 0;
}
.ritz .waffleGate .s11 {
  border-right: 2px SOLID #000000;
  background-color: #ffffff;
  text-align: left;
  color: #000000;
  font-family: "docs-Rubik", Arial;
  font-size: 12pt;
  vertical-align: middle;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
.ritz .waffleGate .s22 {
  background-color: #ffffff;
  text-align: center;
  color: #434343;
  font-family: "docs-Rubik", Arial;
  font-size: 10pt;
  vertical-align: bottom;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
.ritz .waffleGate .s23 {
  background-color: #ffffff;
  text-align: center;
  color: #434343;
  font-family: "docs-Rubik", Arial;
  font-size: 10pt;
  vertical-align: top;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
.ritz .waffleGate .s14 {
  border-bottom: 2px SOLID #000000;
  background-color: #ffffff;
  text-align: left;
  color: #000000;
  font-family: "docs-Rubik", Arial;
  font-size: 12pt;
  vertical-align: bottom;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
.ritz .waffleGate .s10 {
  border-bottom: 2px SOLID #000000;
  border-right: 2px SOLID #000000;
  background-color: #ffffff;
  text-align: left;
  color: #000000;
  font-family: "docs-Rubik", Arial;
  font-size: 12pt;
  vertical-align: bottom;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
.ritz .waffleGate .s13 {
  border-bottom: 2px SOLID #000000;
  border-right: 2px SOLID #000000;
  background-color: #ffffff;
  text-align: left;
  color: #000000;
  font-family: "docs-Rubik", Arial;
  font-size: 12pt;
  vertical-align: middle;
  white-space: normal;
  overflow: hidden;
  word-wrap: break-word;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
.ritz .waffleGate .s19 {
  background-color: #ffffff;
  text-align: center;
  font-weight: bold;
  color: #000000;
  font-family: "docs-Rubik", Arial;
  font-size: 12pt;
  vertical-align: bottom;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
.ritz .waffleGate .s20 {
  border-bottom: 2px SOLID #434343;
  background-color: #ffffff;
  text-align: center;
  font-weight: bold;
  color: #000000;
  font-family: "docs-Rubik", Arial;
  font-size: 12pt;
  vertical-align: bottom;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
.ritz .waffleGate .s7 {
  border-right: 2px SOLID #000000;
  background-color: #ffffff;
  text-align: left;
  font-weight: bold;
  color: #000000;
  font-family: "docs-Rubik", Arial;
  font-size: 12pt;
  vertical-align: bottom;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
.ritz .waffleGate .s5 {
  background-color: #ffffff;
  text-align: center;
  font-weight: bold;
  color: #000000;
  font-family: "docs-Rubik", Arial;
  font-size: 12pt;
  vertical-align: middle;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
.ritz .waffleGate .s0 {
  background-color: #ffffff;
  text-align: left;
  color: #000000;
  font-family: "docs-Rubik", Arial;
  font-size: 12pt;
  vertical-align: bottom;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
